import { html } from "lit-element";
import { guard } from "effector";

import { LightDomEl } from "./light-dom";
import {
    onSetDrawingMode,
    drawingControlStore,
    onSetGoogleMap,
    googleMapStore,
} from "../effector/appDomain/appDomain";

import { GMAP_DRAWING_MODE } from "mapping-ui-apps/quoting/src/const/drawingMode";
const { CIRCLE, POLYGON } = GMAP_DRAWING_MODE;
import { IDX_DRAWING_CONTROL } from "mapping-ui-apps/quoting/src/const/mapControlsRegistry";

import "../../styles/drawing-control.scss";
import {
    distributionBuildTypeStore,
    DISTRIBUTION_BUILD_TYPES,
} from "../effector/appDomain/buildStore";

class DrawingControl extends LightDomEl {
    constructor() {
        super();

        guard(drawingControlStore, {
            filter: () => null !== googleMapStore.getState(),
        }).watch((mode) => {
            this.mode = mode;
        });

        // need it once only to bind control to the map
        const undo = onSetGoogleMap.watch((map) => {
            map.controls[global.google.maps.ControlPosition.TOP_CENTER].setAt(
                IDX_DRAWING_CONTROL,
                this
            );
            undo();
        });

        // control is displayed only when user choses to build distribution themselves
        distributionBuildTypeStore.updates.watch((buildType) => {
            this.active = DISTRIBUTION_BUILD_TYPES.MAP === buildType;
        });
    }

    static get properties() {
        return {
            active: { type: Boolean },
            mode: { type: String },
        };
    }

    render() {
        return html`
            <ul class="${this.active ? "active" : ""}">
                <li
                    @click=${() =>
                        onSetDrawingMode(CIRCLE === this.mode ? null : CIRCLE)}
                    class="${CIRCLE === this.mode ? "active" : ""}"
                    title="Radius selection"
                >
                    <svg viewBox="0 0 1024 1024">
                        <path
                            d="M830.338 193.664c-91.869-91.869-214.016-142.464-343.938-142.464s-252.067 50.595-343.936 142.464-142.464 214.014-142.464 343.936 50.595 252.069 142.464 343.938 214.014 142.462 343.936 142.462 252.069-50.594 343.938-142.462 142.462-214.016 142.462-343.938-50.594-252.067-142.462-343.936zM486.4 972.8c-239.97 0-435.2-195.23-435.2-435.2s195.23-435.2 435.2-435.2c239.97 0 435.2 195.23 435.2 435.2s-195.23 435.2-435.2 435.2z"
                        ></path>
                    </svg>
                </li>
                <li
                    @click=${() =>
                        onSetDrawingMode(
                            POLYGON === this.mode ? null : POLYGON
                        )}
                    class="${POLYGON === this.mode ? "active" : ""}"
                    title="Polygon selection"
                >
                    <svg viewBox="0 0 1024 1024">
                        <path
                            d="M793.598 972.8c-4.205 0-8.422-1.034-12.256-3.126l-153.595-83.778c-12.413-6.768-16.986-22.32-10.218-34.731 6.77-12.414 22.322-16.992 34.733-10.216l107.442 58.603-8.424-50.542c-2.325-13.947 7.098-27.136 21.042-29.461 13.941-2.33 27.136 7.096 29.461 21.042l17.067 102.4c1.611 9.661-2.434 19.394-10.413 25.070-4.418 3.144-9.619 4.739-14.838 4.739z"
                        ></path>
                        <path
                            d="M759.434 768.005c-12.29 0-23.134-8.875-25.221-21.397l-17.066-102.4c-1.36-8.155 1.302-16.464 7.15-22.309l51.2-51.2c9.997-9.997 26.206-9.997 36.203 0 9.998 9.997 9.998 26.206 0 36.203l-41.885 41.885 14.899 89.406c2.325 13.946-7.098 27.136-21.043 29.459-1.422 0.237-2.838 0.35-4.238 0.352z"
                        ></path>
                        <path
                            d="M896 512c-6.552 0-13.102-2.499-18.101-7.499-9.998-9.997-9.998-26.206 0-36.203l16.024-16.026-104.53-17.422c-13.947-2.325-23.368-15.514-21.043-29.461s15.533-23.368 29.459-21.043l153.6 25.6c9.459 1.576 17.243 8.307 20.17 17.438s0.504 19.133-6.277 25.914l-51.2 51.2c-5 5.003-11.55 7.502-18.102 7.502z"
                        ></path>
                        <path
                            d="M691.232 418.136c-1.398 0-2.814-0.115-4.238-0.352l-51.2-8.533c-8.080-1.346-15.027-6.477-18.69-13.802l-51.2-102.395c-6.323-12.646-1.198-28.024 11.448-34.346 12.643-6.323 28.024-1.197 34.346 11.448l45.37 90.736 38.341 6.39c13.947 2.325 23.368 15.514 21.043 29.461-2.085 12.518-12.933 21.392-25.219 21.392z"
                        ></path>
                        <path
                            d="M230.402 972.8c-5.221 0-10.421-1.595-14.842-4.739-7.981-5.677-12.022-15.41-10.413-25.069l49.034-294.206-195.482-195.485c-6.781-6.781-9.203-16.782-6.277-25.914s10.71-15.862 20.17-17.438l294.341-49.058 122.17-244.339c4.336-8.674 13.202-14.152 22.898-14.152 0 0 0 0 0 0 9.698 0 18.562 5.478 22.898 14.152l25.6 51.2c6.322 12.646 1.195 28.024-11.45 34.346-12.646 6.325-28.024 1.198-34.347-11.45l-2.701-5.403-105.101 210.203c-3.664 7.326-10.61 12.458-18.69 13.803l-258.133 43.022 169.626 169.626c5.846 5.845 8.509 14.155 7.149 22.309l-42.557 255.344 235.446-128.426c7.642-4.17 16.875-4.166 24.518 0l25.603 13.966c12.411 6.77 16.984 22.32 10.214 34.733-6.77 12.411-22.318 16.984-34.733 10.214l-13.344-7.278-269.341 146.912c-3.835 2.093-8.053 3.126-12.258 3.126z"
                        ></path>
                    </svg>
                </li>
            </ul>
        `;
    }
}

customElements.define("rm-drawing-control", DrawingControl);
