import { html } from "lit-element";

import { StepBase } from "./step-base";
import {
    contactStore,
    contactValid,
    onUpdateContact,
} from "../effector/contactDomain";
import "../../styles/contact.scss";

class StepContactDetails extends StepBase {
    constructor() {
        super();

        contactStore.watch((contact) => (this.contact = contact));
        contactValid.watch((valid) => {
            this.valid = valid;
            this.complete = Object.keys(this.valid).reduce(
                (acc, item) => acc && this.valid[item],
                true
            );
        });

        // Temporary message due to elevated COVID alert level
        // onUserMessage({level: 'warning', title: 'COVID-19 Level 4 Lockdown Update', message: 'ReachMedia will remain open for business while we work from home for the safety of everyone. We will continue to support you by providing the marketing services and advice you need.'});
    }

    static get properties() {
        return {
            contact: { type: Object },
            valid: { type: Object },
            complete: { type: Boolean },
        };
    }

    updateContact(e, payload) {
        // set custom modified class - this method used only in inputs that handle it
        e.target.classList.add("modified");

        // update store
        onUpdateContact(payload);
    }

    isValid(field) {
        return this.valid[field];
    }

    render() {
        return html`
            <section class="contact-details">
                <h2>First, tell us how to Reach you</h2>
                <aside>
                    <p>
                        Before we take you to the quoting tool, tell us a bit
                        about you and your business, so we can get your quote to
                        you quickly and understand what you want to achieve.
                    </p>
                </aside>

                <section class="contact-form">
                    <div class="name-wrapper">
                        <div class="first-name">
                            <label>First Name</label>
                            <input
                                type="text"
                                name="first_name"
                                .value=${this.contact.firstName}
                                @input=${(e) =>
                                    onUpdateContact({
                                        firstName: e.target.value,
                                    })}
                            />
                        </div>

                        <div class="last-name">
                            <label>Last Name</label>
                            <input
                                type="text"
                                name="last_name"
                                .value=${this.contact.lastName}
                                @input=${(e) =>
                                    onUpdateContact({
                                        lastName: e.target.value,
                                    })}
                            />
                        </div>
                    </div>

                    <label class="${this.isValid("email") ? "" : "required"}">
                        Email
                        <small>required</small>
                    </label>
                    <input
                        type="email"
                        name="email"
                        .value=${this.contact.email}
                        @input=${(e) =>
                            onUpdateContact({ email: e.target.value })}
                    />

                    <label class="${this.isValid("email") ? "" : "required"}">
                        Phone
                        <small>required</small>
                    </label>
                    <input
                        type="tel"
                        name="phone"
                        .value=${this.contact.phone}
                        @input=${(e) =>
                            onUpdateContact({ phone: e.target.value })}
                    />

                    <label>Company Name</label>
                    <input
                        type="text"
                        name="company"
                        .value=${this.contact.companyName}
                        @input=${(e) =>
                            onUpdateContact({ companyName: e.target.value })}
                    />

                    <label>Company Website</label>
                    <input
                        type="text"
                        name="company_website"
                        .value=${this.contact.companyWebsite}
                        @input=${(e) =>
                            onUpdateContact({ companyWebsite: e.target.value })}
                    />

                    <!--label>Your Role</label>
                <select @input=${(e) =>
                        this.updateContact(e, { role: e.target.value })}>
                    <option value="" disabled selected>Please select</option> 
                    <option>Owner/operator</option> 
                    <option>General Manager</option>
                    <option>Marketing manager / director</option>
                    <option>Marketing coordinator</option>
                    <option>Administrator</option>
                    <option>Agency/media</option>
                    <option>Other</option>
                </select-->

                    <!--label>Company Size</label>
                <select @input=${(e) =>
                        this.updateContact(e, { companySize: e.target.value })}>
                    <option value="" disabled selected>Please select</option> 
                    <option>1 - 20</option>
                    <option>20 - 50</option>
                    <option>50 - 100</option>
                    <option>100 - 500</option>
                    <option>500 +</option>
                </select-->

                    <label>Company Industry</label>
                    <select
                        @input=${(e) =>
                            this.updateContact(e, {
                                companyIndustry: e.target.value,
                            })}
                    >
                        >
                        <option value="" disabled selected>
                            Please select
                        </option>
                        <option>Marketing & Advertising</option>
                        <option>Retail</option>
                        <option>Government</option>
                        <option>Education</option>
                        <option>Health, Wellness & Fitness</option>
                        <option>Farming</option>
                        <option>Other</option>
                    </select>
                </section>

                <section class="step-controls">
                    <button
                        class="cta ${this.complete ? "" : "inactive"}"
                        @click=${() => this.fadeOutTo(2)}
                    >
                        Next
                    </button>
                </section>
            </section>
        `;
    }
}

customElements.define("rm-step-contact-details", StepContactDetails);
