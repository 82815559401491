import { html } from "lit-element";

import debounce from "mapping-ui-apps/quoting/src/services/debounce";

import { LightDomEl } from "./light-dom";
import "../../styles/caps-control.scss";

import {
    DISTRIBUTION_BUILD_TYPES,
    distributionBuildTypeStore,
    onDistributionBuildTypeUpdate,
    onUpdateDistributionVolume,
    onResetDistributionVolume,
} from "../effector/appDomain/buildStore";
const { BUDGET, VOLUME, MAP } = DISTRIBUTION_BUILD_TYPES;

class CapsControl extends LightDomEl {
    static get properties() {
        return {
            buildType: { type: String },
            volumeInputEl: { type: Object },
        };
    }

    firstUpdated() {
        this.volumeInputEl = document.getElementById("build-volume");

        distributionBuildTypeStore.watch(
            (buildType) => (this.buildType = buildType)
        );
        onResetDistributionVolume.watch(() => (this.volumeInputEl.value = ""));
    }

    render() {
        return html`
            <h4>Build the distribution (2 ways below)</h4>
            <!-- section>
                <span>
                    <input type="radio" name="build-type" id="build-type-budget"
                        ?checked=${BUDGET === this.buildType}
                        @click=${() => onDistributionBuildTypeUpdate(BUDGET)}>
                    <label for="build-type-budget">My budget is</label>
                </span>
                <span>
                    <strong>$</strong> 
                    <input type="number" placeholder="Budget in dollars"
                        ?disabled=${BUDGET !== this.buildType}>
                </span>
            </section>

            <section>
                <ul>
                    <li class="connector"></li>
                    <li>or</li>
                    <li class="connector"></li>
                </ul>
            </section -->

            <section>
                <span>
                    <input
                        type="radio"
                        name="build-type"
                        id="build-type-volume"
                        ?checked=${VOLUME === this.buildType}
                        @click=${() => onDistributionBuildTypeUpdate(VOLUME)}
                    />
                    <label for="build-type-volume">Number of flyers</label>
                </span>
                <input
                    type="number"
                    placeholder="Total number of flyers"
                    id="build-volume"
                    @input=${debounce(
                        (e) =>
                            VOLUME !== this.buildType
                                ? null
                                : onUpdateDistributionVolume(e.target.value),
                        250
                    )}
                    ?disabled=${VOLUME !== this.buildType}
                />
            </section>
            <small class="${VOLUME !== this.buildType ? "dimmed" : ""}"
                >Location is required to build distribution this way</small
            >

            <section>
                <ul>
                    <li class="connector"></li>
                    <li>OR</li>
                    <li class="connector"></li>
                </ul>
            </section>

            <section>
                <span>
                    <input
                        type="radio"
                        name="build-type"
                        id="build-type-map"
                        ?checked=${MAP === this.buildType}
                        @click=${() => onDistributionBuildTypeUpdate(MAP)}
                    />
                    <label for="build-type-map"
                        >I want to select distribution area on the map</label
                    >
                </span>
            </section>
            <small class="${MAP !== this.buildType ? "dimmed" : ""}"
                >Use drawing tools on the top of the map to outline your
                distribution territory</small
            >
        `;
    }
}

customElements.define("rm-caps-control", CapsControl);
