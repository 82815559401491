import cloneDeep from "lodash-es/cloneDeep";
import { combine } from "effector";

import { QuoteDomain } from "./quoteDomain";
import { roundsDataStore } from "../appDomain/buildStore";
import { onUpdateQuoteSubtotalMatrix } from "./quoteDetailsStore";

import {
    calcVolumesMatrix,
    territoriesSubtotal,
} from "mapping-ui-apps/quoting/src/services/volumes";
import guid from "mapping-ui-apps/quoting/src/services/guid";
import { GMAP_ROUNDS_LAYERS } from "mapping-ui-apps/quoting/src/const/dataLayers";
import { VOLUME_SLICES } from "mapping-ui-apps/quoting/src/const/volumeSlices";

const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
const { INCLUSIVE, EXCLUSIVE, RESIDENTIAL, BUSINESS, FARMER, DAIRY } =
    VOLUME_SLICES;

export const onUpdateRounds = QuoteDomain.event();
export const onResetRounds = QuoteDomain.event();

export const onUpdateTerritoryLocation = QuoteDomain.event();
export const onResetTerritoryLocation = QuoteDomain.event();

export const onUpdateTerritoryVolumesMatrix = QuoteDomain.event();
export const onResetTerritoryVolumesMatrix = QuoteDomain.event();

export const territoryLocationStore = QuoteDomain.store({
    center: null,
    address: null,
    formatted: null,
});

territoryLocationStore.on(onUpdateTerritoryLocation, (state, payload) => ({
    ...payload,
}));
territoryLocationStore.reset(onResetTerritoryLocation);

// this is hardcoded value and not meant to get updated in this application
export const territoryVolumeSelectionsStore = QuoteDomain.store({
    [URBAN]: [EXCLUSIVE],
    [POSTIES]: [EXCLUSIVE],
    [RURAL]: [FARMER, RESIDENTIAL],
    [POBOXES]: [RESIDENTIAL, BUSINESS, FARMER],
    [COUNTERS]: [RESIDENTIAL, FARMER],
});

export const territoryVolumesMatrixStore = QuoteDomain.store({
    [URBAN]: {
        [INCLUSIVE]: 0,
        [EXCLUSIVE]: 0,
    },
    [POSTIES]: {
        [INCLUSIVE]: 0,
        [EXCLUSIVE]: 0,
        [BUSINESS]: 0,
    },
    [RURAL]: {
        [RESIDENTIAL]: 0,
        [FARMER]: 0,
        [DAIRY]: 0,
    },
    [POBOXES]: {
        [RESIDENTIAL]: 0,
        [BUSINESS]: 0,
        [FARMER]: 0,
    },
    [COUNTERS]: {
        [RESIDENTIAL]: 0,
        [FARMER]: 0,
    },
});

territoryVolumesMatrixStore.on(
    onUpdateTerritoryVolumesMatrix,
    (state, payload) => cloneDeep(payload)
);
territoryVolumesMatrixStore.reset(onResetTerritoryVolumesMatrix);

export const territoryNetworksStore = QuoteDomain.store([]);
export const onUpdateTerritoryNetworks = QuoteDomain.event();
territoryNetworksStore.on(onUpdateTerritoryNetworks, (state, payload) => [
    ...state,
    ...payload,
]);

export const territoryRoundsStore = QuoteDomain.store({});
territoryRoundsStore.on(onUpdateRounds, (state, payload) => ({
    ...cloneDeep(state),
    ...payload,
}));
territoryRoundsStore.reset(onResetRounds);

combine(territoryRoundsStore, territoryNetworksStore, (rounds, networks) => {
    onUpdateTerritoryVolumesMatrix(
        calcVolumesMatrix(networks, {}, rounds, roundsDataStore.getState())
    );
});

combine({
    volumesMatrix: territoryVolumesMatrixStore,
    volumeSelections: territoryVolumeSelectionsStore,
    networkSelections: territoryNetworksStore,
}).updates.watch((territory) => {
    const subtotal = territoriesSubtotal([territory]);
    onUpdateQuoteSubtotalMatrix(subtotal);
});

export const territoryStore = combine(
    {
        id: `rm_${guid()}`,
        colour: "#9eb9e0", // hardcode default colour coming from this application
        location: territoryLocationStore,
        volumeSelections: territoryVolumeSelectionsStore,
        volumesMatrix: territoryVolumesMatrixStore,
        networkSelections: territoryNetworksStore,
        rounds: territoryRoundsStore,
    },
    (sample) => {
        if ("development" === process.env.NODE_ENV) {
            console.log(
                "%cTerritory Store updated",
                "background-color: #ebeff8;"
            );
            console.dir(sample);
        }
        return sample;
    }
);
