import { combine, guard } from "effector";

import {
    distributionVolumeStore,
    distributionBuildTypeStore,
    DISTRIBUTION_BUILD_TYPES,
} from "../effector/appDomain/buildStore";
import {
    territoryLocationStore,
    territoryNetworksStore,
} from "../effector/quoteDomain/territoryStore";
import { httpFetchRoundsData } from "../effector/appDomain/fetchRoundsEffect";
import { onUserMessage } from "../effector/appDomain/appDomain";

export const getRoundsByVolume = async (request) => {
    const { location, volume, networks } = request;
    let data = { features: [] };

    try {
        const response = await fetch(
            `${process.env.URL_GEO_API}/roundsByVolume`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    centerX: location.center.lng,
                    centerY: location.center.lat,
                    volume,
                    networks,
                }),
            }
        );

        if (response.ok) {
            data = await response.json();
        } else {
            onUserMessage({
                level: "warning",
                title: "Uh-uh",
                message: "Apologies, something went wrong, please try again!",
            });
            "development" === process.env.NODE_ENV
                ? console.warn("Request failed!")
                : null;
            return [];
        }
    } catch (error) {
        "development" === process.env.NODE_ENV ? console.error(error) : null;
        return [];
    }

    return data.features;
};

/**
 * Basic validation so that we don't call this service unless we have all the request bits right
 */
const validVolumeBuild = combine(
    territoryLocationStore,
    distributionVolumeStore,
    territoryNetworksStore,
    distributionBuildTypeStore,
    (location, volume, networks, buildType) => {
        if (DISTRIBUTION_BUILD_TYPES.VOLUME !== buildType) return false;
        if (null === location.center) return false;
        if (!location.center.lat || !location.center.lng) return false;
        if (null === volume) return false;
        if (volume < 1) return false;
        if (networks.length < 1) return false;
        return true;
    }
);

guard({
    source: combine({
        location: territoryLocationStore,
        volume: distributionVolumeStore,
        networks: territoryNetworksStore,
    }),
    filter: validVolumeBuild,
    target: httpFetchRoundsData,
});
