import clonedeep from 'lodash-es/cloneDeep';
import { PRINTING_CONFIG_UPDATE, PRINTING_CONFIG_RESET } from '../actions/actionTypes';

export const initialState = {
        'printer': null,
        'paperSize': 'A4',
        'paperType': 'Matte',
        'pagination': 'Double Sided',
        'colours': 'CMYK',
        'gsm': '150gsm',
        'margin': 25,
        'pageCount': 1
};

export const printers = [
    'Ricoh',
    'Alliance',
    'Crucial Colour',
    'Print Lounge'
];

export const paperSize = [
    'A0',
    'A1',
    'A2',
    'A3',
    'A4',
    'A5',
    'A6',
    'DL',
    'A3 folded to A4',
    'A4 folded to A5',
    'A4 folded to DL'
];

export const paperType = [
    'Matte',
    'Gloss'
];

export const paperSheetsPsm = {
    'A0' : 1,
    'A1' : 2,
    'A2' : 4,
    'A3' : 8,
    'A4' : 16,
    'A5' : 32,
    'A6' : 64,
    'DL' : 48,
    'A3 folded to A4' : 8,
    'A4 folded to A5' : 16,
    'A4 folded to DL': 16
};

export const pagination = [
    'Single Sided',
    'Double Sided'
];

export const colours = [
    'CMYK',
    'B&W'
];

export const gsm = [
    '100gsm',
    '130gsm',
    '150gsm',
    '170gsm',
    '200gsm',
    '250gsm',
    '300gsm',
    '350gsm'
];

export const printingSpecs = (state = initialState, action) => {
    switch (action.type) {
        case PRINTING_CONFIG_UPDATE:
        {
            const next = clonedeep(state);
            return {...next, ...action.payload}
        }
        case PRINTING_CONFIG_RESET:
            return clonedeep(initialState);
        default:
            return state;
    }
}