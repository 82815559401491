import { html } from "lit-element";

import { LightDomEl } from "./light-dom";
import { stepStore } from "../effector/appDomain/appDomain";

import "../../styles/contact.scss";
import { clientSwitchStep } from "./step-base";

class StepProgress extends LightDomEl {
    static get properties() {
        return {
            step: { type: Number },
        };
    }

    firstUpdated() {
        stepStore.watch((step) => (this.step = step));
    }

    /**
     * This is not the best way to solve w/o coupling like this, but it does the job on the scale of this app.
     */
    goToStep(step) {
        document
            .querySelector(`[step="${this.step}"]`)
            .dispatchEvent(clientSwitchStep(step));

        // hardcoded GA
        switch (step) {
            case 1:
                window.dataLayer.push({
                    pagePath: "/get-a-quote",
                    pageTitle: "step 1 - get a quote",
                    event: "quotepageview",
                });
                break;
            case 2:
                window.dataLayer.push({
                    pagePath: "/quote-details",
                    pageTitle: "step 2 - quote details",
                    event: "quotepageview",
                });
                break;
        }
    }

    render() {
        return html`
            <ul>
                <li
                    @click=${() => this.goToStep(1)}
                    class="${1 === this.step ? "selected" : ""}"
                >
                    <h4>1</h4>
                </li>
                <li class="connector"></li>
                <li
                    @click=${() => this.goToStep(2)}
                    class="${2 === this.step ? "selected" : ""}"
                >
                    <h4>2</h4>
                </li>
                <li class="connector"></li>
                <li
                    @click=${() => this.goToStep(3)}
                    class="${3 === this.step ? "selected" : ""}"
                >
                    <h4>3</h4>
                </li>
            </ul>
        `;
    }
}

customElements.define("rm-step-progress", StepProgress);
