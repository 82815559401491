export let map;

export const mapInit = (el) => {
    map = new google.maps.Map(el, {
        streetViewControl: false,
        mapTypeControl: false,
        clickableIcons: false,
    });
}

/* 
    Default NZ latLng -41.1417474, 173.1288766
*/
const defaultPosition = async (lat = -41.1417474, lng = 173.1288766) => {
    let latLng;
    return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition((position) => {
            // Center on user's current location if geolocation prompt allowed
            latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            resolve({latLng, zoom: 13});
        }, () => {
            // User denied geolocation prompt - default to params
            latLng = new google.maps.LatLng(lat, lng);
            resolve({latLng, zoom: 5});
        });
    });
}

export const mapDefaultPan = () => {
    defaultPosition().then((position) => {
        map.setCenter(position.latLng);
        map.setZoom(position.zoom);
    });
}

export const mapBootstrappedEvent = new Event('client:map:bootstrapped');
