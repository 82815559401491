import cloneDeep from "lodash-es/cloneDeep";
import { combine } from "effector";

import { AppDomain } from "./appDomain";
import { onResetRounds } from "../quoteDomain/territoryStore";

export const DISTRIBUTION_BUILD_TYPES = {
    BUDGET: "BUDGET",
    VOLUME: "VOLUME",
    MAP: "MAP",
};

export const distributionBuildTypeStore = AppDomain.store(
    DISTRIBUTION_BUILD_TYPES.VOLUME
);
export const onDistributionBuildTypeUpdate = AppDomain.event();

distributionBuildTypeStore.on(
    onDistributionBuildTypeUpdate,
    (state, payload) => payload
);

export const distributionBudgetStore = AppDomain.store(null);
export const onDistributionBudgetUpdate = AppDomain.event();
export const onDistributionBudgetReset = AppDomain.event();

distributionBudgetStore.on(
    onDistributionBudgetUpdate,
    (state, payload) => payload
);
distributionBudgetStore.reset(onDistributionBudgetReset);

export const distributionVolumeStore = AppDomain.store(null);
export const onUpdateDistributionVolume = AppDomain.event();
export const onResetDistributionVolume = AppDomain.event();

distributionVolumeStore.on(onUpdateDistributionVolume, (state, payload) => {
    const volume = parseInt(payload);
    return isNaN(volume) || volume < 1 ? null : volume;
});

distributionVolumeStore.reset(onResetDistributionVolume);

// define extra reset logic - get all rounds store reset when volume gets reset
distributionVolumeStore.updates.watch((volume) => {
    if (null === volume) {
        onResetRoundsData();
        onResetRounds();
    }
});

export const roundsDataStore = AppDomain.store({});
export const onResetRoundsData = AppDomain.event();
export const onUpdateRoundsData = AppDomain.event();

roundsDataStore.reset(onResetRoundsData);
roundsDataStore.on(onUpdateRoundsData, (state, payload) => ({
    ...cloneDeep(state),
    ...payload,
}));

export const buildStore =
    "development" === process.env.NODE_ENV
        ? combine(
              {
                  roundsDataStore,
                  distributionBuildTypeStore,
                  distributionBudgetStore,
                  distributionVolumeStore,
              },
              (sample) => {
                  console.log(
                      "%cBuild Store updated",
                      "background-color: #ebeff8;"
                  );
                  console.dir(sample);
              }
          )
        : null;
