import { contactValid, onUpdateContact } from "../effector/contactDomain";
import { onUpdateStep } from "../effector/appDomain/appDomain";

// this service helps with prefetching contact details from the URL string passed from external pages - website etc
// check the URL search parameters for the contact details
if (window.location.search) {
    // automatically redirect to the quote builder step if contact information in URL is valid
    contactValid.watch((valid) => {
        if (
            Object.keys(valid).reduce((acc, item) => acc && valid[item], true)
        ) {
            onUpdateStep(2);
        }
    });

    const params = new URLSearchParams(window.location.search);
    const contact = {
        firstName: params.get("first_name"),
        lastName: params.get("last_name"),
        email: params.get("email"),
        phone: params.get("phone"),
        companyName: params.get("company_name"),
        companyWebsite: params.get("website"),
        companyIndustry: params.get("industry"),
    };

    onUpdateContact(contact);
}
