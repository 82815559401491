import { html } from "lit-element";
import { unsafeHTML } from "lit-html/directives/unsafe-html.js";

import "./quote-panel";
import "./drawing-control";
import { StepBase } from "./step-base";
import { mapApiLoaded } from "../services/mapApiLoaded";
import {
    onSetGoogleMap,
    googleMapStore,
    onUpdateBuilderPanelVisible,
} from "../effector/appDomain/appDomain";
import { territoryLocationStore } from "../effector/quoteDomain/territoryStore";

import { setMarkerAndPan } from "mapping-ui-apps/quoting/src/services/marker";

import "../../styles/map-builder.scss";

// default New Zealand lat/long
const defaultNZ = {
    lat: -41.1417474,
    lng: 173.1288766,
};

class StepMapBuilder extends StepBase {
    constructor() {
        super();
        // bootstrapping routine
        document.addEventListener(mapApiLoaded.type, async () => {
            new Promise((resolve) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Center on user's current location if geolocation prompt allowed
                        resolve(
                            new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            )
                        );
                    },
                    () => {
                        // User denied geolocation prompt - default to params
                        resolve(
                            new google.maps.LatLng(defaultNZ.lat, defaultNZ.lng)
                        );
                    }
                );
            }).then((center) => {
                this.defaultCenter = center;
                this.map = new google.maps.Map(this.querySelector(".map"), {
                    streetViewControl: false,
                    mapTypeControl: false,
                    clickableIcons: false,
                    center: this.defaultCenter,
                    zoom: 10,
                });
                onSetGoogleMap(this.map);
            });
        });

        // marker management
        territoryLocationStore.updates.watch((location) => {
            if (null !== location.center) {
                setMarkerAndPan(location.center, {
                    // override defaults from Sputnik
                    map: googleMapStore.getState(),
                    icon: {
                        fillColor: "#2f446a",
                        fillOpacity: 1,
                        strokeColor: "#2f446a",
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        scale: 0.03,
                        anchor: new google.maps.Point(512, 1024),
                        path: "M512 1024c-7.856 0-15.277-3.606-20.13-9.784-2.931-3.73-72.587-92.864-143.229-224.51-41.581-77.491-74.742-153.568-98.565-226.118-30.043-91.499-45.277-177.758-45.277-256.387 0-169.39 137.81-307.2 307.2-307.2s307.2 137.81 307.2 307.2c0 78.629-15.234 164.888-45.278 256.386-23.822 72.55-56.984 148.629-98.565 226.118-70.64 131.646-140.298 220.781-143.229 224.51-4.851 6.179-12.272 9.786-20.128 9.786zM512 51.2c-141.158 0-256 114.842-256 256 0 166.597 74.914 341.176 137.758 458.296 46.186 86.074 92.634 154.306 118.237 189.938 25.709-35.789 72.429-104.432 118.688-190.76 62.643-116.902 137.317-291.163 137.317-457.474 0-141.158-114.84-256-256-256zM512 460.8c-84.696 0-153.6-68.904-153.6-153.6s68.904-153.6 153.6-153.6 153.6 68.904 153.6 153.6-68.904 153.6-153.6 153.6zM512 204.8c-56.464 0-102.4 45.936-102.4 102.4s45.936 102.4 102.4 102.4c56.464 0 102.4-45.936 102.4-102.4s-45.936-102.4-102.4-102.4z",
                    },
                });
            }
        });
    }

    static get properties() {
        return {
            map: { type: Object },
            defaultCenter: { type: Object },
        };
    }

    render() {
        return html`
            <section class="map-builder">
                <div class="tab-open">
                    <button @click=${() => onUpdateBuilderPanelVisible(true)}>
                        ${unsafeHTML("&#9654")}
                    </button>
                </div>
                <section class="map"></section>
                <rm-drawing-control></rm-drawing-control>
                <rm-quote-panel panelStateOn></rm-quote-panel>
            </section>
        `;
    }
}

customElements.define("rm-step-map-builder", StepMapBuilder);
