import { html } from "lit-element";

import { LightDomEl } from "./light-dom";

import "../../styles/user-message.scss";
import {
    userMessageStore,
    resetUserMessage,
} from "../effector/appDomain/appDomain";

class UserMessage extends LightDomEl {
    constructor() {
        super();
        this.setVisuals(null);

        userMessageStore.updates.watch((data) => {
            if (null == data) {
                // this.classList.remove('drop-out');
                this.classList.add("drop-back");
            } else {
                // this is where store data used
                this.setVisuals(data);

                this.classList.add("drop-out");
                this.classList.remove("drop-back");
            }
        });

        this.addEventListener(
            "animationend",
            (e) => {
                if ("drop-back" === e.animationName) {
                    // flush animation classes
                    this.classList.remove("drop-out", "drop-back");
                    // reset message
                    this.setVisuals(null);
                }
            },
            false
        );
    }

    static get properties() {
        return {
            level: { type: String, reflect: true },
            title: { type: String, reflect: true },
            message: { type: String, reflect: true },
        };
    }

    setVisuals(data) {
        if (null == data) {
            this.level = "";
            this.title = "";
            this.message = "";
        } else {
            this.level = data.level || "";
            this.title = data.title || "";
            this.message = data.message || "";
        }
    }

    render() {
        return html`
            <button @click=${() => resetUserMessage()}>&Cross;</button>
            <section>
                <h4 class="${this.level}">${this.title}</h4>
            </section>
            <article>${this.message}</article>
        `;
    }
}

customElements.define("rm-user-message", UserMessage);
