import {
    onResetTerritoryLocation,
    onResetRounds,
    onResetTerritoryVolumesMatrix,
} from "../effector/quoteDomain/territoryStore";
import { onResetDistributionVolume } from "../effector/appDomain/buildStore";
import {
    onResetQuoteFees,
    onResetQuoteTotal,
    quoteFeesStore,
    onUpdateQuoteTotal,
} from "../effector/quoteDomain/quoteDetailsStore";
import { onUpdateContact } from "../effector/contactDomain";

const minFee = 600;
/**
 * Total cost calculation logic is based on requirement of a minimal $600 charge for
 * volume and freight combined.
 */
const adminFee = 50;

quoteFeesStore.updates.watch((fees) => {
    const { volumeCost, freightCost, networkManagementCost } = fees;
    const subCost = volumeCost + freightCost + networkManagementCost;
    const total =
        (0 < subCost && subCost < minFee ? minFee : subCost) + adminFee;
    onUpdateQuoteTotal(total.toFixedNumber(2));
});

export const startOver = () => {
    onResetTerritoryLocation();
    onResetDistributionVolume();
    onResetRounds();
    onResetTerritoryVolumesMatrix();
    onResetQuoteFees();
    onUpdateContact({ notes: "" });
    onResetQuoteTotal();
};
