import { GMAP_ROUNDS_LAYERS } from '../const/dataLayers';
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
import { VOLUME_SLICES } from '../const/volumeSlices';
const { INCLUSIVE, EXCLUSIVE, RESIDENTIAL, BUSINESS, FARMER, DAIRY } = VOLUME_SLICES;

const roundVolumeMapper = (roundData, layer, slice) => {
    if (!roundData || !roundData.properties || !roundData.properties.volumes) {
        return 0;
    }
    
    const volumes = roundData.properties.volumes;
    switch (layer) {
        case URBAN:
        case POSTIES:
        case RURAL:
            return volumes[layer][slice] || 0;
        case POBOXES:
            // include counters
            return (volumes[layer][slice] || 0) + (volumes[COUNTERS][slice] || 0)
    }
};

const sliceVolumes = (layer, slice, rounds, roundsData) => {
    return Object.keys(rounds)
        .filter(roundId => rounds[roundId].selected && layer == rounds[roundId].layer)
        .map(roundId => roundVolumeMapper(roundsData[roundId], layer, slice))
        .reduce((acc, volume) => acc + volume, 0);
};

export const layerVolumes = (layer, rounds, roundsData) => {
    // changes happen to layer level granularity only, so it can be optimised like this on invocation level
    // TODO: optimise it further so it doesn't walk through all rounds every time
    switch (layer) {
        case URBAN:
            return { [INCLUSIVE]: sliceVolumes(URBAN, INCLUSIVE, rounds, roundsData), [EXCLUSIVE]: sliceVolumes(URBAN, EXCLUSIVE, rounds, roundsData) };
        case POSTIES: 
            return { [INCLUSIVE]: sliceVolumes(POSTIES, INCLUSIVE, rounds, roundsData), [EXCLUSIVE]: sliceVolumes(POSTIES, EXCLUSIVE, rounds, roundsData), [BUSINESS]: sliceVolumes(POSTIES, BUSINESS, rounds, roundsData) };
        case RURAL:
            return { [INCLUSIVE]: sliceVolumes(RURAL, INCLUSIVE, rounds, roundsData), [RESIDENTIAL]: sliceVolumes(RURAL, RESIDENTIAL, rounds, roundsData), [FARMER]: sliceVolumes(RURAL, FARMER, rounds, roundsData), [DAIRY]: sliceVolumes(RURAL, DAIRY, rounds, roundsData) };
        case POBOXES:
            return { [RESIDENTIAL]: sliceVolumes(POBOXES, RESIDENTIAL, rounds, roundsData), [BUSINESS]: sliceVolumes(POBOXES, BUSINESS, rounds, roundsData), [FARMER]: sliceVolumes(POBOXES, FARMER, rounds, roundsData) };
    }
    // we are not supposed to get here
    throw(new Error(`Unexpected call with layer = [${layer}]`));
};

export const calcVolumesMatrix = (layers, volumesMatrix, rounds, roundsData) => {
    const volumes = {...volumesMatrix};
    layers.forEach(layer => volumes[layer] = layerVolumes(layer, rounds, roundsData));
    return volumes;
};

export const layerSubtotal = (layerSlices, layerVolumes) => {
    return layerSlices.reduce((acc, slice) => acc + layerVolumes[slice], 0);
};

export const territoriesSubtotal = (territories) => {
    const subtotalDefault = {[URBAN]:0, [POSTIES]:0, [RURAL]:0, [POBOXES]:0};
    return territories.map(territory => 
        territory.networkSelections.reduce((acc, layer) => {
            acc[layer] = layerSubtotal(territory.volumeSelections[layer], territory.volumesMatrix[layer]);
            return acc;
        }, {...subtotalDefault})
    ).reduce((acc, territorySubtotal) => {
        Object.keys(territorySubtotal).forEach(layer => acc[layer] += territorySubtotal[layer]);
        return acc;
    }, {...subtotalDefault});
}

export const territoriesTotal = (subtotalMatrix) => {
    return Object.keys(subtotalMatrix).reduce((acc, layer) => acc + subtotalMatrix[layer], 0);
}
