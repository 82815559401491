import { html } from "lit-element";

import { LightDomEl } from "./light-dom";
import { mapApiLoaded } from "../services/mapApiLoaded";

import {
    onUpdateTerritoryLocation,
    territoryLocationStore,
    onResetTerritoryLocation,
} from "../effector/quoteDomain/territoryStore";

import "../../styles/location-finder.scss";
import { guard, combine } from "effector";
import {
    distributionVolumeStore,
    distributionBuildTypeStore,
} from "../effector/appDomain/buildStore";

export const getAddressComponents = (components) => {
    const street_number =
        (components.find((item) => item.types.includes("street_number")) || {})
            .short_name || "";
    const postal_code =
        (components.find((item) => item.types.includes("postal_code")) || {})
            .short_name || "";
    const street_name =
        (components.find((item) => item.types.includes("route")) || {})
            .short_name || "";
    const suburb =
        (components.find((item) => item.types.includes("sublocality")) || {})
            .short_name || "";
    const city =
        (components.find((item) => item.types.includes("locality")) || {})
            .short_name || "";
    const area =
        (
            components.find((item) =>
                item.types.includes("administrative_area_level_1")
            ) || {}
        ).short_name || "";

    if (street_number || street_name) {
        return {
            main_text: `${street_number} ${street_name}`.trim(),
            secondary_text: `${suburb}, ${city}`.trim(),
        };
    } else if (postal_code || suburb) {
        return {
            main_text: `${postal_code} ${suburb}`.trim(),
            secondary_text: `${city}`,
        };
    } else {
        return {
            main_text: `${city}`,
            secondary_text: `${area}`,
        };
    }
};

class LocationFinder extends LightDomEl {
    constructor() {
        super();

        // bias search towards current user location
        const bounds = null;

        document.addEventListener(mapApiLoaded.type, () => {
            this.geocoder = new google.maps.Geocoder();

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    const bounds = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy,
                    });
                });
            }

            // Create the autocomplete object, restricting the search predictions to
            // geographical location types.
            const autocomplete = new google.maps.places.Autocomplete(
                this.inputEl,
                { types: ["geocode"] }
            );
            if (null !== bounds) {
                autocomplete.setBounds(bounds.getBounds());
            }

            // Get place ID only to use geocoding service to fetch the rest
            autocomplete.setFields(["place_id"]);

            // When the user selects an address from the drop-down, get territory updated with address and location
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                this.geocoder.geocode(
                    {
                        placeId: place.place_id,
                    },
                    (geo, status) => {
                        if (
                            google.maps.GeocoderStatus.OK === status &&
                            geo[0]
                        ) {
                            const { main_text, secondary_text } =
                                getAddressComponents(geo[0].address_components);
                            onUpdateTerritoryLocation({
                                center: geo[0].geometry.location.toJSON(),
                                address: geo[0].formatted_address,
                                formatted: {
                                    main_text,
                                    secondary_text,
                                },
                            });
                        }
                    }
                );
            });
        });

        guard(combine(distributionVolumeStore, territoryLocationStore), {
            filter: () => "VOLUME" == distributionBuildTypeStore.getState(),
        }).watch((sample) => {
            const [volume, location] = sample;
            this.highlightOn = volume > 0 && null == location.center;
        });

        onResetTerritoryLocation.watch(() => (this.inputEl.value = ""));
    }

    static get properties() {
        return {
            inputEl: { type: Object },
            highlightOn: { type: Boolean },
        };
    }

    firstUpdated() {
        this.inputEl = this.querySelector("input");
    }

    render() {
        return html`
            <h4>Pin your location</h4>
            <section>
                <input
                    type="text"
                    placeholder="Start typing an address"
                    autocomplete="off"
                />
                ${this.highlightOn
                    ? html`<small class="required"
                          >Location is required to build this
                          distribution</small
                      >`
                    : html`<small
                          >Location that you'd like to distribute around</small
                      >`}
            </section>
        `;
    }
}

customElements.define("rm-location-finder", LocationFinder);
