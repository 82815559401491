import { combine, guard } from "effector";

import {
    drawingControlStore,
    googleMapStore,
    onSetDrawingMode,
} from "../effector/appDomain/appDomain";
import {
    distributionBuildTypeStore,
    DISTRIBUTION_BUILD_TYPES,
} from "../effector/appDomain/buildStore";

import { GMAP_DRAWING_MODE } from "mapping-ui-apps/quoting/src/const/drawingMode";
import { httpFetchRoundsData } from "../effector/appDomain/fetchRoundsEffect";
import {
    disableFeaturesInteractivity,
    enableFeaturesInteractivity,
} from "./renderDataLayers";
const { POLYGON } = GMAP_DRAWING_MODE;

// initial snap-on
let snapOn = false;
// gobal module reusable objects
let livePath, polyline, polylineStarter;
let mousedownHandler, mousemoveHandler;

const polylineOptions = {
    fillColor: "#b2b3b8",
    fillOpacity: 0.1,
    strokeColor: "#003492",
    strokeWeight: 1,
    clickable: false,
    editable: false,
    zIndex: 0,
};

const finishPolygon = async () => {
    // finish the polygon if there are enough points
    if (livePath.getLength() > 2) {
        livePath.push(livePath.getAt(0));

        try {
            await httpFetchRoundsData(polyline);
        } catch (error) {
            console.log(error);
        } finally {
            // reset the tool
            livePath.clear();
            polylineStarter.setVisible(false);
            // reset control to default
            onSetDrawingMode(null);
        }
        return true;
    }
    return false;
};

const getPolylineStarter = (map) => {
    const polylineStarter = new google.maps.Marker({
        map,
        clickable: true,
        visible: false,
        icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#003492",
            fillOpacity: 0.75,
            strokeColor: "transparent", // just to make snap-on area bigger
            strokeWeight: 5,
            scale: 7,
        },
    });

    polylineStarter.addListener("mouseover", () => {
        snapOn = true;
    });

    polylineStarter.addListener("mouseout", () => {
        snapOn = false;
    });

    polylineStarter.addListener("click", async () => {
        // finish polygon conditionally, disable polygon starter prior to make sure
        // it's not visible if polygon is correctly finished
        polylineStarter.setVisible(false);
        (await finishPolygon()) ? null : polylineStarter.setVisible(true);
    });

    return polylineStarter;
};

const getPolyline = (map) => {
    return new google.maps.Polyline({ ...polylineOptions, map });
};

export const polygonMode = combine({
    drawingMode: drawingControlStore,
    buildMode: distributionBuildTypeStore,
}).map((next, _prev) => {
    return (
        DISTRIBUTION_BUILD_TYPES.MAP === next.buildMode &&
        POLYGON === next.drawingMode
    );
});

guard(combine({ googleMapStore, polygonMode }), {
    filter: (modes) => null !== modes.googleMapStore,
}).watch((modes) => {
    const { googleMapStore: map, polygonMode: modeActive } = modes;

    if (!modeActive) {
        // reset map and disable handlers
        map.setOptions({
            draggable: true,
            gestureHandling: "greedy",
            draggableCursor: "",
        });
        enableFeaturesInteractivity();
        try {
            mousedownHandler ? mousedownHandler.remove() : null;
            mousemoveHandler ? mousemoveHandler.remove() : null;
        } catch (error) {
            // TODO: Report to Sentry?
        }
        return;
    }

    // have to disable gestureHandling as mousemove event handling becomes buggy
    map.setOptions({ gestureHandling: "none", draggableCursor: "pointer" });
    disableFeaturesInteractivity();

    if (!polylineStarter) {
        polylineStarter = getPolylineStarter(map);
    }

    if (!livePath) {
        livePath = new google.maps.MVCArray();
    }

    if (!polyline) {
        polyline = getPolyline(map);
        polyline.setPath(livePath);
    }

    mousedownHandler = map.addListener("mousedown", (event) => {
        if (0 == livePath.getLength()) {
            polylineStarter.setPosition(event.latLng);
            polylineStarter.setVisible(true);
        }
        livePath.push(event.latLng);
    });

    mousemoveHandler = map.addListener("mousemove", (event) => {
        if (livePath.getLength() > 1) {
            if (snapOn) {
                livePath.setAt(livePath.getLength() - 1, livePath.getAt(0));
            } else {
                livePath.setAt(livePath.getLength() - 1, event.latLng);
            }
        } else if (1 == livePath.getLength()) {
            // very first live point
            livePath.push(event.latLng);
        }
    });
});
