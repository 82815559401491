import { createDomain } from "effector";

const isEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/gm;
    return re.test(String(email).toLowerCase());
};

const isPhone = (phone) => {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    return re.test(String(phone).toLowerCase());
};

export const ContactDomain = createDomain();
export const onUpdateContact = ContactDomain.event();

export const contactStore = ContactDomain.store({
    firstName: "",
    lastName: "",
    companyName: "",
    companyWebsite: "",
    email: "",
    phone: "",
    role: "",
    companySize: "",
    companyIndustry: "",
    notes: "",
});

contactStore.on(onUpdateContact, (state, payload) => ({
    ...state,
    ...payload,
}));

export const contactValid = contactStore.map((contact) => ({
    firstName: true,
    lastName: true,
    companyName: true,
    email: isEmail(contact.email),
    phone: isPhone(contact.phone),
}));

contactStore.watch((sample) => {
    if ("development" === process.env.NODE_ENV) {
        console.log(
            "%cContact Details Store updated",
            "background-color: #ebeff8;"
        );
        console.dir(sample);
    }
});
