import { onUserMessage } from "../effector/appDomain/appDomain";

/**
 *
 * @param {Object} polygon Google polygon object
 */
export const getRoundsByPolygon = async (polygon) => {
    let data = { features: [] };
    // a single polygon supported here
    // flip lat/lng to PostGIS lng/lat notation
    const path = polygon
        .getPath()
        .getArray()
        .map((latLng) => `${latLng.lng()} ${latLng.lat()}`);

    try {
        const response = await fetch(
            `${process.env.URL_GEO_API}/roundsByPolygon`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(path),
            }
        );

        if (response.ok) {
            data = await response.json();
        } else {
            onUserMessage({
                level: "warning",
                title: "Uh-uh",
                message: "Apologies, something went wrong, please try again!",
            });
            "development" === process.env.NODE_ENV
                ? console.warn("Request failed!")
                : null;
            return [];
        }
    } catch (error) {
        "development" === process.env.NODE_ENV ? console.error(error) : null;
        return [];
    }

    return data.features;
};
