import { googleMapStore } from "../effector/appDomain/appDomain";

import { GMAP_ROUNDS_LAYERS } from "mapping-ui-apps/quoting/src/const/dataLayers";
const { URBAN, RURAL } = GMAP_ROUNDS_LAYERS;
import {
    onUpdateTerritoryNetworks,
    onUpdateRounds,
    territoryRoundsStore,
} from "../effector/quoteDomain/territoryStore";

export const mapDataLayers = {};

// bootstrap google map layers once the map is ready
googleMapStore.updates.watch((map) => {
    mapDataLayers[URBAN] = new global.google.maps.Data({
        map,
        style: {
            fillColor: "#003492",
            fillOpacity: 0.3,
            strokeColor: "#003492",
            strokeWeight: 1,
            zIndex: 2,
            visible: true,
        },
    });

    mapDataLayers[RURAL] = new global.google.maps.Data({
        map,
        style: {
            fillColor: "#e932d6",
            fillOpacity: 0.3,
            strokeColor: "#e932d6",
            strokeWeight: 1,
            zIndex: 3,
            visible: true,
        },
    });

    // Assign round visibility toggle handler
    Object.keys(mapDataLayers).forEach((layer) => {
        mapDataLayers[layer].addListener("click", (event) => {
            // Toggle round selection
            const roundId = event.feature.getId();
            const round = territoryRoundsStore.getState()[roundId];
            onUpdateRounds({
                [roundId]: {
                    selected: !round.selected,
                    layer: round.layer,
                },
            });
        });
    });

    // Init data layers
    onUpdateTerritoryNetworks([URBAN, RURAL]);
});
