export const mapApiLoaded = new Event("map:api:loaded");

/**
 * This is map API js callback
 * We transform it into internal event to bind more listeners
 */
window.mapApiLoaded = () => {
    if ("development" === process.env.NODE_ENV) {
        console.log("%cGoogle Map API Loaded", "background-color: #ebeff8;");
    }
    document.dispatchEvent(mapApiLoaded);
};
