import { GMAP_ROUNDS_LAYERS } from "mapping-ui-apps/quoting/src/const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES } = GMAP_ROUNDS_LAYERS;

export const translateNetwork = {
    Urban: URBAN,
    Rural: RURAL,
    Postie: POSTIES,
    "PO Box and Counters": POBOXES,
};

/**
 * Payload transformation helper
 *
 * @param {Array<any>} payload Array of geoJSON features as per spec
 * @returns {any} hashmap of geoJSON features with walk_id as a key
 */
export const transformRoundaDataPayload = (payload) => {
    return payload.reduce((acc, feature) => {
        acc[feature.properties.walk_id] = feature;
        return acc;
    }, {});
};

/**
 * Stores data convertyer
 *
 * @param {RoundsData} data roundsDataStore state
 * @returns {TerritoryRounds} value to update territoryRounds store with
 */
export const loadTerritoryRounds = (data) =>
    Object.keys(data).reduce((acc, roundId) => {
        acc[roundId] = {
            selected: true,
            layer: translateNetwork[data[roundId].properties.network],
        };
        return acc;
    }, {});
