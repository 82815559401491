import { httpFetchFreightQuote } from "../effector/quoteDomain/quoteDomain";
import {
    quoteSubtotalMatrixStore,
    onUpdateQuoteFees,
    onUpdateQuoteDistribution,
    quoteDistributionStore,
} from "../effector/quoteDomain/quoteDetailsStore";
import {
    onSetApiInFlight,
    onUserMessage,
} from "../effector/appDomain/appDomain";
import { mergeFreightDistribution } from "../services/distribution";

let lastReqTime = 0;

const getFreightQuotes = async (request) => {
    lastReqTime = Date.now();
    const result = { quote: 0, reqTime: lastReqTime, freightDistribution: {} };

    const requests = Object.keys(request).flatMap(async (channel) => {
        if (request[channel] > 0) {
            const quote = await getFreightQuote({
                [channel]: request[channel],
            });
            result.freightDistribution[channel] = quote;
            result.quote += quote;
        }
        return [];
    });

    try {
        await Promise.all(requests);

        return result;
    } catch (error) {
        "development" === process.env.NODE_ENV ? console.error(error) : null;
    }
};

const getFreightQuote = async (request) => {
    const response = await fetch(
        `${process.env.URL_QUOTE_API}/freight?json=${encodeURIComponent(
            JSON.stringify(request)
        )}`,
        {
            method: "GET",
            mode: "cors",
        }
    );

    if (response.ok) {
        const res = await response.json();
        return res.quote || 0;
    } else {
        // Somethig went wrong
        onUserMessage({
            level: "warning",
            title: "Uh-uh",
            message:
                "We failed to quote your distribution automatically, but you can submit it anyway and we will get in touch.",
        });
        "development" === process.env.NODE_ENV
            ? console.warn("Request failed!")
            : null;
    }

    return 0;
};

httpFetchFreightQuote.use(getFreightQuotes);

httpFetchFreightQuote.pending.watch((state) => {
    // Indicate the progress
    onSetApiInFlight(state);
});

httpFetchFreightQuote.done.watch(({ _params, result }) => {
    const { quote: freightCost, reqTime, freightDistribution } = result;
    const newDistribution = mergeFreightDistribution(
        quoteDistributionStore.getState(),
        freightDistribution
    );

    if (lastReqTime <= reqTime) {
        // respect only most recent request
        onUpdateQuoteFees({ freightCost });
        onUpdateQuoteDistribution(newDistribution);
    }
});

httpFetchFreightQuote.fail.watch(({ _params, _error }) => {
    // TODO: Report failure to Sentry?
    // TODO: Retry?
});

quoteSubtotalMatrixStore.watch((volumes) => {
    if (Object.values(volumes).reduce((acc, value) => acc + value, 0) < 1) {
        // no volume is produced by selection, or it's got reset
        onUpdateQuoteFees({ freightCost: 0 });
    } else {
        httpFetchFreightQuote(volumes);
    }
});
