import {
    quoteSubtotalMatrixStore,
    onUpdateQuoteFees,
} from "../effector/quoteDomain/quoteDetailsStore";

/**
 * Logic to calculate network management fee to provide respective quote line
 * $4 per 1000
 * @param {Number} volume
 */

let cached = null;

export const getNetworkManagement = async (flush = false) => {
    if (null != cached && !flush) {
        return cached;
    }

    let res, data;
    try {
        res = await fetch(`${process.env.URL_QUOTE_API}/network`);
    } catch (e) {
        console.error(e);
    }

    try {
        data = await res.json();
    } catch (e) {
        console.error(e);
    }

    if (data.error) {
        console.error(data.error);
        return 0;
    } else {
        cached = data.rates;
    }

    return cached;
};

export const networkManagement = (rate, volume) => {
    return ((rate * volume) / 1000).toFixedNumber(2);
};

const territoriesTotal = (subtotalMatrix) => {
    return Object.keys(subtotalMatrix).reduce(
        (acc, layer) => acc + subtotalMatrix[layer],
        0
    );
};

// handle Network Management fee
quoteSubtotalMatrixStore.watch(async (matrix) => {
    const rates = await getNetworkManagement();
    const networkManagementCost = networkManagement(
        rates["T1"],
        territoriesTotal(matrix)
    );
    onUpdateQuoteFees({ networkManagementCost });
});
