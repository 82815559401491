import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { auth } from "./firebaseInit";

let firebaseToken = null;

/**
 * We use anonymous authenticated user to be able to use Firestore properly to save quotes.
 */
signInAnonymously(auth).catch((error) => {
    // Handle Errors here.
    console.group("Firebase authentication error");
    console.error(error.code);
    console.error(error.message);
    console.groupEnd();
});

onAuthStateChanged(auth, async (user) => {
    if (user) {
        // User is signed in.
        firebaseToken = await user.getIdToken();
        if ("development" === process.env.NODE_ENV) {
            console.log(
                "%cFirebase Token for Anonymous user updated",
                "background-color: #ebeff8;"
            );
            console.log(firebaseToken);
        }
    } else {
        // User is signed out.
        firebaseToken = null;
        if ("development" === process.env.NODE_ENV) {
            console.log(
                "%cFirebase Anonymous user signed out",
                "background-color: #fae7ec;"
            );
            console.log(firebaseToken);
        }
    }
});

export const getFirebaseToken = () => firebaseToken;
