import { onUserMessage } from "../effector/appDomain/appDomain";

/**
 *
 * @param {Object} circle Google Circle object
 */
export const getRoundsByCircle = async (circle) => {
    let data = { features: [] };

    try {
        const response = await fetch(
            `${process.env.URL_GEO_API}/roundsByCircle?centerX=${circle
                .getCenter()
                .lng()}&centerY=${circle
                .getCenter()
                .lat()}&radius=${circle.getRadius()}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.ok) {
            data = await response.json();
        } else {
            onUserMessage({
                level: "warning",
                title: "Uh-uh",
                message: "Apologies, something went wrong, please try again!",
            });
            "development" === process.env.NODE_ENV
                ? console.warn("Request failed!")
                : null;
            return [];
        }
    } catch (error) {
        "development" === process.env.NODE_ENV ? console.error(error) : null;
        return [];
    }

    return data.features;
};
