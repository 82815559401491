import { AppDomain, onSetApiInFlight } from "./appDomain";
import {
    onResetRoundsData,
    onUpdateRoundsData,
    roundsDataStore,
} from "./buildStore";
import { onResetRounds, onUpdateRounds } from "../quoteDomain/territoryStore";
import {
    transformRoundaDataPayload,
    loadTerritoryRounds,
} from "../../services/rounds";

export const httpFetchRoundsData = AppDomain.effect();

httpFetchRoundsData.pending.watch((state) => {
    // the logic is that every time we get this request we must reset rounds related data
    if (true === state) {
        onResetRoundsData();
        onResetRounds();
    }
    // Indicate the progress
    onSetApiInFlight(state);
});

httpFetchRoundsData.fail.watch(({ _params, _error }) => {
    // TODO: Report failure to Sentry?
});

httpFetchRoundsData.done.watch(({ _params, result }) => {
    // load results into roundsData store
    onUpdateRoundsData(transformRoundaDataPayload(result));
    // load rounds into territory
    onUpdateRounds(loadTerritoryRounds(roundsDataStore.getState()));
});
