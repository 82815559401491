import { LightDomEl } from "./light-dom";
import { html } from "lit-element";
import dialogPolyfill from "dialog-polyfill";

import "../../styles/dialog.scss";

import {
    contactStore,
    contactValid,
    onUpdateContact,
} from "../effector/contactDomain";
import {
    onSetHelpMeDialog,
    helpMeDialogStore,
} from "../effector/appDomain/appDomain";
import { saveQuote } from "../services/saveQuote";

class DialogHelpMe extends LightDomEl {
    constructor() {
        super();

        helpMeDialogStore.watch((state) => (this.open = state));

        contactStore.watch((contact) => (this.contact = contact));
        contactValid.watch((valid) => {
            this.valid = valid;
            this.complete = Object.keys(this.valid).reduce(
                (acc, item) => acc && this.valid[item],
                true
            );
        });
    }

    static get properties() {
        return {
            open: { type: Boolean },
            contact: { type: Object },
            valid: { type: Object },
        };
    }

    firstUpdated() {
        // polyfilling
        const dialog = this.querySelector("dialog");
        dialogPolyfill.registerDialog(dialog);
    }

    isValid(field) {
        return this.valid[field];
    }

    render() {
        return html`
            <dialog ?open=${this.open}>
                <header>
                    <h2>How can we help you?</h2>
                </header>
                <article>
                    <p>
                        If we've confused you or your distribution plans are
                        somewhat more sophisticated, not to worry! Please add
                        your notes below and request the quote this way. We will
                        use the following contact details.
                    </p>

                    <p>If this is not right, please update it here.</p>

                    <section class="contact-form">
                        <div class="name-wrapper">
                            <div class="first-name">
                                <input
                                    type="text"
                                    name="first_name"
                                    placeholder="First Name"
                                    .value=${this.contact.firstName}
                                    @input=${(e) =>
                                        onUpdateContact({
                                            firstName: e.target.value,
                                        })}
                                />
                            </div>

                            <div class="last-name">
                                <input
                                    type="text"
                                    name="last_name"
                                    placeholder="Last Name"
                                    .value=${this.contact.lastName}
                                    @input=${(e) =>
                                        onUpdateContact({
                                            lastName: e.target.value,
                                        })}
                                />
                            </div>
                        </div>

                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            .value=${this.contact.email}
                            @input=${(e) =>
                                onUpdateContact({ email: e.target.value })}
                        />

                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            .value=${this.contact.phone}
                            @input=${(e) =>
                                onUpdateContact({ phone: e.target.value })}
                        />
                    </section>

                    <section class="notes">
                        <label>Notes and Comments</label>
                        <textarea
                            .value=${this.contact.notes}
                            @input=${(e) =>
                                onUpdateContact({ notes: e.target.value })}
                        ></textarea>
                    </section>
                </article>
                <footer>
                    <button @click=${() => onSetHelpMeDialog(false)}>
                        Cancel
                    </button>
                    <button
                        class="cta"
                        @click=${() => {
                            onSetHelpMeDialog(false);
                            saveQuote();
                        }}
                    >
                        Send
                    </button>
                </footer>
            </dialog>
        `;
    }
}

customElements.define("rm-dialog-help-me", DialogHelpMe);
