import { combine } from "effector";

import { QuoteDomain } from "./quoteDomain";
import { paperWeightStore } from "./printDetailsStore";

import { GMAP_ROUNDS_LAYERS } from "mapping-ui-apps/quoting/src/const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;

export const quoteTotalStore = QuoteDomain.store(0);

export const quoteFeesStore = QuoteDomain.store({
    locked: false,
    printingLine: false,
    extraCopiesFee: 0,
    extraCopies: 0,
    customWeightBracket: null,
    volumeCost: 0,
    freightCost: 0,
    printCost: 0,
    networkManagementCost: 0,
    dairyFarmerPremium: 0,
    apartmentsPremium: 0,
});

export const quoteDistributionStore = QuoteDomain.store({
    [URBAN]: { freight: 0, volume: 0 },
    [POSTIES]: { freight: 0, volume: 0 },
    [RURAL]: { freight: 0, volume: 0 },
    [POBOXES]: { freight: 0, volume: 0 },
    [COUNTERS]: { freight: 0, volume: 0 },
});

export const quoteSubtotalMatrixStore = QuoteDomain.store({
    [URBAN]: 0,
    [POSTIES]: 0,
    [RURAL]: 0,
    [POBOXES]: 0,
    [COUNTERS]: 0,
});

export const onUpdateQuoteTotal = QuoteDomain.event();
export const onResetQuoteTotal = QuoteDomain.event();

quoteTotalStore.on(onUpdateQuoteTotal, (state, payload) => payload);

export const onUpdateQuoteDistribution = QuoteDomain.event();
export const onResetQuoteDistribution = QuoteDomain.event();

quoteDistributionStore.on(
    onUpdateQuoteDistribution,
    (_state, payload) => payload
);
quoteDistributionStore.reset(onResetQuoteDistribution);

export const onUpdateQuoteSubtotalMatrix = QuoteDomain.event();
export const onResetQuoteSubtotalMatrix = QuoteDomain.event();

quoteSubtotalMatrixStore.on(
    onUpdateQuoteSubtotalMatrix,
    (state, payload) => payload
);

export const onUpdateQuoteFees = QuoteDomain.event();
export const onResetQuoteFees = QuoteDomain.event();

quoteFeesStore.on(onUpdateQuoteFees, (state, payload) => ({
    ...state,
    ...payload,
}));
quoteFeesStore.reset(onResetQuoteFees);

export const quoteDetailsStore = combine(
    quoteFeesStore,
    quoteDistributionStore,
    quoteSubtotalMatrixStore,
    paperWeightStore,
    quoteTotalStore,
    (quoteFees, distribution, subtotalMatrix, paperWeight, total) => {
        return {
            ...quoteFees,
            distribution,
            subtotalMatrix,
            weight: paperWeight,
            total,
        };
    }
);

quoteDetailsStore.updates.watch((sample) => {
    if ("development" === process.env.NODE_ENV) {
        console.log(
            "%cQuote Details Store updated",
            "background-color: #ebeff8;"
        );
        console.dir(sample);
    }
});

quoteTotalStore.updates.watch((sample) => {
    if ("development" === process.env.NODE_ENV) {
        console.log(
            "%cTotal Quote Store updated",
            "background-color: #ebeff8;"
        );
        console.dir(sample);
    }
});
