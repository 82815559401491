import { html } from "lit-element";
import { unsafeHTML } from "lit-html/directives/unsafe-html.js";
import { combine } from "effector";

import { LightDomEl } from "./light-dom";

import "./location-finder";
import "./caps-control";
import "./quote-summary";

import { quoteSubtotalMatrixStore } from "../effector/quoteDomain/quoteDetailsStore";
import {
    apiInFlightStore,
    onUpdateBuilderPanelVisible,
    builderPanelVisibleStore,
    onSetHelpMeDialog,
} from "../effector/appDomain/appDomain";
import { saveQuote } from "../services/saveQuote";
import { startOver } from "../services/quote";

import "../../styles/quote-panel.scss";

class QuotePanel extends LightDomEl {
    constructor() {
        super();

        this.quotable = false;
        combine(
            apiInFlightStore,
            quoteSubtotalMatrixStore,
            (inFlight, volumes) => {
                this.quotable =
                    !inFlight &&
                    Object.values(volumes).reduce(
                        (acc, value) => acc + value,
                        0
                    ) > 0;
            }
        );

        builderPanelVisibleStore.updates.watch(
            (visible) => (this.panelStateOn = visible)
        );
    }

    static get properties() {
        return {
            quotable: {
                type: Boolean,
            },
            panelStateOn: {
                type: Boolean,
                reflect: true,
            },
        };
    }

    updated(changed) {
        if (
            changed.has("panelStateOn") &&
            undefined !== changed.get("panelStateOn")
        ) {
            if (this.panelStateOn && !changed.get("panelStateOn")) {
                this.classList.remove("slide-out");
                this.classList.add("slide-in");
            }
            if (!this.panelStateOn && changed.get("panelStateOn")) {
                this.classList.remove("slide-in");
                this.classList.add("slide-out");
            }
        }
    }

    render() {
        return html`
            <div class="tab-close">
                <button @click=${() => onUpdateBuilderPanelVisible(false)}>
                    ${unsafeHTML("&#9664")}
                </button>
            </div>

            <rm-location-finder></rm-location-finder>

            <rm-caps-control></rm-caps-control>

            <div class="helper">
                <h4>Not sure what to do?</h4>
                <button class="help-me" @click=${() => onSetHelpMeDialog(true)}>
                    Help Me!
                </button>
            </div>

            <div class="helper">
                <h4>Reset current screen only.</h4>
                <button @click=${() => startOver()}>Start Over</button>
            </div>

            <rm-quote-summary></rm-quote-summary>

            <div class="quote-control">
                <button
                    class="cta ${this.quotable ? "" : "inactive"}"
                    @click=${() => saveQuote()}
                >
                    Get Quote
                </button>
            </div>
        `;
    }
}

customElements.define("rm-quote-panel", QuotePanel);
