import {
    territoryNetworksStore,
    territoryRoundsStore,
} from "../effector/quoteDomain/territoryStore";
import { mapDataLayers } from "./initDataLayers";
import { roundsDataStore } from "../effector/appDomain/buildStore";

export const flushInteractiveLayers = () => {
    Object.keys(mapDataLayers).forEach((layer) =>
        mapDataLayers[layer].forEach((feature) =>
            mapDataLayers[layer].remove(feature)
        )
    );
};

export const enableFeaturesInteractivity = () => {
    Object.keys(mapDataLayers)
        .filter((layer) => mapDataLayers[layer].getStyle().visible)
        .forEach((layer) => {
            const dataLayer = mapDataLayers[layer];
            dataLayer.setStyle({
                ...dataLayer.getStyle(),
                clickable: true,
                cursor: "pointer",
            });
            // enable individual feature interactivity
            dataLayer.forEach((feature) => {
                dataLayer.overrideStyle(feature, dataLayer.getStyle());
            });
        });
};

export const disableFeaturesInteractivity = () => {
    Object.keys(mapDataLayers)
        .filter((layer) => mapDataLayers[layer].getStyle().visible)
        .forEach((layer) => {
            const dataLayer = mapDataLayers[layer];
            dataLayer.setStyle({ ...dataLayer.getStyle(), clickable: false });
            // enable individual feature interactivity
            dataLayer.forEach((feature) => {
                dataLayer.overrideStyle(feature, dataLayer.getStyle());
            });
        });
};

const renderRounds = (rounds, roundsData, networksSelection, flush = false) => {
    if (Object.entries(rounds).length === 0) {
        flushInteractiveLayers();
        return;
    }

    if (flush) {
        flushInteractiveLayers();
    }

    // google map data layer features will use walk_id as the feature ID
    const featureOpts = { idPropertyName: "walk_id" };

    Object.keys(rounds).forEach((walk_id) => {
        const round = rounds[walk_id];
        if (!round || !round.layer || undefined === round.selected) {
            // TODO: report this issues so we can track down the source
            return;
        }

        // make sure invisible layers don't get features rendered
        if (!networksSelection.includes(round.layer)) {
            return;
        }

        const roundDef = roundsData[walk_id];
        let feature;

        // avoid doubled-features, another approach could be flushing entire layer every time we get here,
        // which has to be done to each feature one by one
        // make sure geometry exists too
        feature = mapDataLayers[round.layer].getFeatureById(walk_id);
        if (!feature && roundDef && null !== roundDef.geometry) {
            feature = mapDataLayers[round.layer].addGeoJson(
                roundDef,
                featureOpts
            )[0];
        }

        // make sure feature actually exists, although all the measures are taken
        if (!feature) {
            // TODO: report this issue so we can track down the source
            return;
        }

        if (round.selected) {
            mapDataLayers[round.layer].overrideStyle(feature, {
                ...mapDataLayers[round.layer].getStyle(),
                visible: true,
            });
        } else {
            mapDataLayers[round.layer].overrideStyle(feature, {
                fillOpacity: 0,
                visible: true,
            });
        }
    });
};

// TODO: make it a wee bit more legible - get rid of getState methods by combining or something similar
territoryRoundsStore.watch((rounds) =>
    renderRounds(
        rounds,
        roundsDataStore.getState(),
        territoryNetworksStore.getState()
    )
);
