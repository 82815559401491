import { LitElement } from "lit-element";

import { onUpdateStep, stepStore } from "../effector/appDomain/appDomain";
export const clientSwitchStep = (step) =>
    new CustomEvent("client:switch:step", {
        detail: { step },
        bubbles: true,
        composed: true,
    });

export class StepBase extends LitElement {
    constructor() {
        super();
        // create listener to remove animation style (garbage collector)
        this.addEventListener("animationend", () => {
            this.classList.remove("fadeOut", "fadeIn");
        });

        // listen to external events
        this.addEventListener(
            clientSwitchStep(null).type,
            (e) => {
                this.fadeOutTo(e.detail.step);
            },
            false
        );
    }

    static get properties() {
        return {
            step: { type: Number },
        };
    }

    /**
     * Render into the light DOM
     */
    createRenderRoot() {
        return this;
    }

    firstUpdated() {
        // set initial state
        if (stepStore.getState() === this.step) {
            this.fadeIn();
        }

        // ensure fadeIn animation is enabled on state change
        onUpdateStep.watch((step) =>
            step === this.step ? this.fadeIn() : null
        );
    }

    fadeIn() {
        // apply animation
        this.classList.remove("inactive");
        this.classList.add("fadeIn");
    }

    fadeOutTo(step) {
        // enable next step when animation is finished
        this.addEventListener(
            "animationend",
            (e) => {
                if ("fadeOut" === e.animationName) {
                    this.classList.add("inactive");
                    onUpdateStep(step);
                }
            },
            { once: true }
        );
        // apply animation
        this.classList.add("fadeOut");
    }
}
