export const GMAP_ROUNDS_LAYERS = {
    URBAN: 'URBAN',
    POSTIES: 'POSTIES',
    RURAL: 'RURAL',
    POBOXES: 'POBOXES',
    COUNTERS: 'COUNTERS',
    INACTIVE: 'INACTIVE'
};

export const reduxActiveRoundsUpdateEvent = new Event('redux:activeTerritory:rounds:update');
export const reduxInactiveRoundsUpdateEvent = new Event('redux:inactiveTerritory:rounds:update');
export const drawingDisableFeaturesInteractivity = new Event('drawing:disable:featuresInteractivity');
export const drawingEnableFeaturesInteractivity = new Event('drawing:enable:featuresInteractivity');
