import { LightDomEl } from "./light-dom";
import { apiInFlightStore } from "../effector/appDomain/appDomain";

class LoadingProgress extends LightDomEl {
    constructor() {
        super();

        this.active = false;
        apiInFlightStore.updates.watch((inFlight) => (this.active = inFlight));
    }

    static get properties() {
        return {
            active: {
                type: Boolean,
                reflect: true,
            },
        };
    }
}

customElements.define("rm-loading-progress", LoadingProgress);
