import { combine } from "effector";

import { httpFetchVolumeQuote } from "../effector/quoteDomain/quoteDomain";
import {
    quoteSubtotalMatrixStore,
    onUpdateQuoteFees,
    quoteDistributionStore,
    onUpdateQuoteDistribution,
} from "../effector/quoteDomain/quoteDetailsStore";
import {
    printDetailsStore,
    paperWeightStore,
} from "../effector/quoteDomain/printDetailsStore";
import {
    onSetApiInFlight,
    onUserMessage,
} from "../effector/appDomain/appDomain";

import { bracketKey } from "mapping-ui-apps/quoting/src/services/weight";
import { mergeVolumeDistribution } from "./distribution";

let lastReqTime = 0;

const getVolumeQuotes = async (request) => {
    lastReqTime = Date.now();
    const result = { quote: 0, reqTime: lastReqTime, volumeDistribution: {} };

    const requests = Object.keys(request.volumes).flatMap(async (channel) => {
        if (request.volumes[channel] > 0) {
            const quote = await getVolumeQuote({
                volumes: {
                    [channel]: request.volumes[channel],
                },
                weight: request.weight,
            });
            result.volumeDistribution[channel] = quote;
            result.quote += quote;
        }
        return [];
    });

    try {
        await Promise.all(requests);

        return result;
    } catch (error) {
        "development" === process.env.NODE_ENV ? console.error(error) : null;
    }
};

const getVolumeQuote = async (request) => {
    const response = await fetch(
        `${process.env.URL_QUOTE_API}/volume?json=${encodeURIComponent(
            JSON.stringify(request)
        )}`,
        {
            method: "GET",
            mode: "cors",
        }
    );

    if (response.ok) {
        const res = await response.json();
        return res.quote || 0;
    } else {
        // Somethig went wrong
        onUserMessage({
            level: "warning",
            title: "Uh-uh",
            message:
                "We failed to quote your distribution automatically, but you can submit it anyway and we will get in touch.",
        });
        "development" === process.env.NODE_ENV
            ? console.warn("Request failed!")
            : null;
    }

    return 0;
};

httpFetchVolumeQuote.pending.watch((state) => {
    // Indicate the progress
    onSetApiInFlight(state);
});

httpFetchVolumeQuote.done.watch(({ _params, result }) => {
    const { quote: volumeCost, reqTime, volumeDistribution } = result;
    const newDistribution = mergeVolumeDistribution(
        quoteDistributionStore.getState(),
        volumeDistribution
    );

    if (lastReqTime <= reqTime) {
        // respect only most recent request
        onUpdateQuoteFees({ volumeCost });
        onUpdateQuoteDistribution(newDistribution);
    }
});

httpFetchVolumeQuote.fail.watch(({ _params, _error }) => {
    // TODO: Report failure to Sentry?
    // TODO: Retry?
    // TODO: Report to user?
});

httpFetchVolumeQuote.use(getVolumeQuotes);

combine({
    paperWeight: paperWeightStore,
    volumes: quoteSubtotalMatrixStore,
    printDetails: printDetailsStore,
}).watch(({ paperWeight, volumes, printDetails }) => {
    const weightBracket = bracketKey(paperWeight * printDetails.pageCount);
    if (Object.values(volumes).reduce((acc, value) => acc + value, 0) < 1) {
        // no volume is produced by selection, or it's got reset
        onUpdateQuoteFees({ volumeCost: 0 });
    } else {
        httpFetchVolumeQuote({ weight: weightBracket, volumes });
    }
});
