import { calcPageWeight } from "mapping-ui-apps/quoting/src/services/weight";

import { QuoteDomain } from "./quoteDomain";

export const onUpdatePrintDetails = QuoteDomain.event();
export const onResetPrintDetails = QuoteDomain.event();

export const printDetailsStore = QuoteDomain.store({
    paperSize: "A4",
    pagination: "Double Sided",
    gsm: "150gsm",
    comments: "",
    pageCount: 1,
    paperType: "Matte",
    printer: null,
    colours: "CMYK",
});

printDetailsStore.reset(onResetPrintDetails);
printDetailsStore.on(onUpdatePrintDetails, (state, payload) => ({
    ...state,
    ...payload,
}));

export const onUpdatePaperWeight = QuoteDomain.event();
export const onResetPaperWeight = QuoteDomain.event();

export const paperWeightStore = QuoteDomain.store(0);

paperWeightStore.reset(onResetPaperWeight);
paperWeightStore.on(onUpdatePaperWeight, (state, payload) => payload);

export const paperSizes = ["A4", "A5", "A6", "DLE"];

export const paperWeightOptions = [
    "100gsm",
    "130gsm",
    "150gsm",
    "170gsm",
    "200gsm",
    "250gsm",
    "300gsm",
    "350gsm",
];

printDetailsStore.watch((sample) => {
    const weight = calcPageWeight(sample.paperSize, sample.gsm);
    onUpdatePaperWeight(weight);

    if ("development" === process.env.NODE_ENV) {
        console.log(
            "%cPrint Details Store updated",
            "background-color: #ebeff8;"
        );
        console.dir(sample);
    }
});
