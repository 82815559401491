export const mergeVolumeDistribution = (
    quoteDistribution,
    volumeDistribution
) => {
    for (const channel of Object.keys(volumeDistribution)) {
        if (!quoteDistribution[channel]) {
            quoteDistribution[channel] = {};
        }
        quoteDistribution[channel].volume = volumeDistribution[channel];
    }

    return quoteDistribution;
};

export const mergeFreightDistribution = (
    quoteDistribution,
    freightDistribution
) => {
    for (const channel of Object.keys(freightDistribution)) {
        if (!quoteDistribution[channel]) {
            quoteDistribution[channel] = {};
        }
        quoteDistribution[channel].freight = freightDistribution[channel];
    }

    return quoteDistribution;
};
