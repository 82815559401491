import {
    distributionBuildTypeStore,
    DISTRIBUTION_BUILD_TYPES,
} from "../effector/appDomain/buildStore";
import { httpFetchRoundsData } from "../effector/appDomain/fetchRoundsEffect";

import { circleMode } from "./circleMode";
import { polygonMode } from "./polygonMode";

import { getRoundsByVolume } from "./roundsByVolume";
import { getRoundsByCircle } from "./roundsByCircle";
import { getRoundsByPolygon } from "./roundsByPolygon";

circleMode.updates.watch((mode) => {
    mode ? httpFetchRoundsData.use(getRoundsByCircle) : null;
});

polygonMode.updates.watch((mode) => {
    mode ? httpFetchRoundsData.use(getRoundsByPolygon) : null;
});

distributionBuildTypeStore.watch((buildType) => {
    DISTRIBUTION_BUILD_TYPES.VOLUME === buildType
        ? httpFetchRoundsData.use(getRoundsByVolume)
        : null;
});
