// Google Tag Manager
window.dataLayer = window.dataLayer || [];
const gtag = () => {
    dataLayer.push(arguments);
};

const gtmJs = (id) => {
    var f = document.getElementsByTagName("script")[0];
    var j = document.createElement("script");
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
    f.parentNode.insertBefore(j, f);
};

gtmJs(`${process.env.GA_ID}`);

gtag("js", new Date());
gtag("config", `${process.env.GA_ID}`);

process.env.GTM_HYPEDEXTER_ID
    ? gtag("config", `${process.env.GTM_HYPEDEXTER_ID}`)
    : null;

// End Google Tag Manager
