import { createDomain } from "effector";

export const AppDomain = createDomain();

export const onUpdateStep = AppDomain.event();
export const onSetGoogleMap = AppDomain.event();
export const onSetDrawingMode = AppDomain.event();
export const onSetApiInFlight = AppDomain.event();
export const onUpdateBuilderPanelVisible = AppDomain.event();
export const onUserMessage = AppDomain.event();
export const resetUserMessage = AppDomain.event();
export const onSetHelpMeDialog = AppDomain.event();

// default step is 1
export const stepStore = AppDomain.store(1);
stepStore.on(onUpdateStep, (state, payload) => payload);

export const googleMapStore = AppDomain.store(null);
googleMapStore.on(onSetGoogleMap, (state, payload) => payload);

export const drawingControlStore = AppDomain.store(null);
drawingControlStore.on(onSetDrawingMode, (state, payload) => payload);

export const apiInFlightStore = AppDomain.store(false);
apiInFlightStore.on(onSetApiInFlight, (state, payload) => payload);

export const builderPanelVisibleStore = AppDomain.store(true);
builderPanelVisibleStore.on(
    onUpdateBuilderPanelVisible,
    (state, payload) => payload
);

export const userMessageStore = AppDomain.store(null);
userMessageStore.on(onUserMessage, (state, payload) => payload);
userMessageStore.reset(resetUserMessage);

export const helpMeDialogStore = AppDomain.store(false);
helpMeDialogStore.on(onSetHelpMeDialog, (state, payload) => payload);
