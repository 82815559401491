import { html } from "lit-element";
import { guard } from "effector";

import { numberFormat } from "mapping-ui-apps/quoting/src/services/numberHelper";

import {
    quoteSubtotalMatrixStore,
    quoteDetailsStore,
    quoteTotalStore,
} from "../effector/quoteDomain/quoteDetailsStore";
import { LightDomEl } from "./light-dom";

import "../../styles/quote-summary.scss";

class QuoteSummary extends LightDomEl {
    constructor() {
        super();
        this.volume = 0;
        this.total = 0;
        this.gst = 0;

        quoteSubtotalMatrixStore.updates.watch((matrix) => {
            this.volume = Object.values(matrix).reduce(
                (acc, value) => acc + value,
                0
            );
        });

        guard(quoteDetailsStore.updates, {
            filter: () => this.volume > 0,
        }).watch(() => {
            const total = quoteTotalStore.getState();
            this.total = total;
            this.gst = total * 0.15;
        });
    }

    static get properties() {
        return {
            volume: { type: Number },
            total: { type: Number },
            gst: { type: Number },
        };
    }

    render() {
        return html`
            <h4>Quote summary</h4>
            <section>
                <span>Mailers volume</span>
                <span class="underline"></span>
                <span
                    ><strong
                        >${numberFormat(this.volume, {
                            minimumFractionDigits: 0,
                        })}</strong
                    ></span
                >
            </section>
            <section>
                <span>Distribution cost <small>(GST inc.)</small></span>
                <span class="underline"></span>
                <span
                    ><strong
                        >$${numberFormat(this.total + this.gst, {
                            minimumFractionDigits: 2,
                        })}</strong
                    ></span
                >
            </section>
        `;
    }
}

customElements.define("rm-quote-summary", QuoteSummary);
