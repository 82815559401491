import { combine, guard } from "effector";

import {
    drawingControlStore,
    googleMapStore,
    onSetDrawingMode,
} from "../effector/appDomain/appDomain";
import {
    distributionBuildTypeStore,
    DISTRIBUTION_BUILD_TYPES,
} from "../effector/appDomain/buildStore";

import { GMAP_DRAWING_MODE } from "mapping-ui-apps/quoting/src/const/drawingMode";
import { httpFetchRoundsData } from "../effector/appDomain/fetchRoundsEffect";
import {
    disableFeaturesInteractivity,
    enableFeaturesInteractivity,
} from "./renderDataLayers";
const { CIRCLE } = GMAP_DRAWING_MODE;

let mouseupHandler, mousedownHandler, mousemoveHandler;
let radiusInfo;
let center, circle;
let drawingOn = false;

const circleOptions = {
    fillColor: "#b2b3b8",
    fillOpacity: 0.1,
    strokeColor: "#003492",
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    zIndex: 0,
};

const getCircle = (map) => {
    return new google.maps.Circle({ map, ...circleOptions });
};

export const circleMode = combine({
    drawingMode: drawingControlStore,
    buildMode: distributionBuildTypeStore,
}).map((next, _prev) => {
    return (
        DISTRIBUTION_BUILD_TYPES.MAP === next.buildMode &&
        CIRCLE === next.drawingMode
    );
});

guard(combine({ googleMapStore, circleMode }), {
    filter: (modes) => null !== modes.googleMapStore,
}).watch((modes) => {
    const { googleMapStore: map, circleMode: modeActive } = modes;

    if (!modeActive) {
        try {
            mousedownHandler ? mousedownHandler.remove() : null;
            mousemoveHandler ? mousemoveHandler.remove() : null;
            mouseupHandler ? mouseupHandler.remove() : null;
        } catch (error) {
            // TODO: Report to Sentry?
        }

        // reset map and disable handlers
        map.setOptions({
            draggable: true,
            gestureHandling: "greedy",
            draggableCursor: "",
        });
        enableFeaturesInteractivity();

        return;
    }

    // disable map interaction
    map.setOptions({
        draggable: false,
        gestureHandling: "none",
        draggableCursor: "pointer",
    });
    disableFeaturesInteractivity();

    // assing handlers
    mousedownHandler = map.addListener("mousedown", (event) => {
        center = event.latLng;
        if (!circle) {
            circle = getCircle(map);
        }
        circle.setVisible(true);
        circle.setCenter(center);
        circle.setRadius(0);
        if (!radiusInfo) {
            radiusInfo = new global.google.maps.InfoWindow();
        }
        radiusInfo.setPosition(center);
        radiusInfo.setContent("&nbsp;0.00 KM");
        radiusInfo.open(map);

        drawingOn = true;
    });

    mousemoveHandler = map.addListener("mousemove", (mousemoveEvent) => {
        if (drawingOn) {
            circle.setRadius(
                google.maps.geometry.spherical.computeDistanceBetween(
                    center,
                    mousemoveEvent.latLng
                )
            );
            radiusInfo.setContent(
                `&nbsp;${(circle.getRadius() / 1000).toFixed(2).toString()} KM`
            );
        }
    });

    mouseupHandler = map.addListener("mouseup", async () => {
        drawingOn = false;
        radiusInfo.close();
        radiusInfo.setContent("");
        try {
            await httpFetchRoundsData(circle);
        } catch (error) {
            // TODO: report error to Sentry
        } finally {
            // get rid of the circle
            circle.setVisible(false);
            // reset control to default
            onSetDrawingMode(null);
        }
    });
});
