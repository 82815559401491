import { combine } from "effector";

import { db } from "./firebaseInit";
import { getFirebaseToken } from "./firebaseAuth";

import {
    territoryStore,
    territoryNetworksStore,
} from "../effector/quoteDomain/territoryStore";
import { quoteDetailsStore } from "../effector/quoteDomain/quoteDetailsStore";
import { printDetailsStore } from "../effector/quoteDomain/printDetailsStore";
import { contactStore } from "../effector/contactDomain";

import { GMAP_ROUNDS_LAYERS } from "mapping-ui-apps/quoting/src/const/dataLayers";
import {
    onUserMessage,
    onSetApiInFlight,
} from "../effector/appDomain/appDomain";
import { startOver } from "./quote";
import { addDoc, collection, updateDoc } from "firebase/firestore";

/**
 * Minimal quote object structure as follows
    {
        origin: "client",
        networkVisibility: {
            URBAN: true,
            RURAL: true,
            POSTIE: false,
            POBOXES: false,
            COUNTERS: false,
        },
        printingSpecs: {
            printer: null,
            paperSize: "A4",
            paperType: "Matte",
            pagination: "Double Sided",
            colours: "CMYK",
            gsm: "150gsm",
            margin: 25,
            pageCount: 1,
            comments: ""
        },
        quoteDetails: {
            locked: false,
            printingLine: false,
            extraCopiesFee: 0,
            extraCopies: 0,
            weight: 9.38,
            customWeightBracket: null,
            volumeCost: 0,
            freightCost: 0,
            printCost: 0,
            networkManagementCost: 0,
            dairyFarmerPremium: 0,
            apartmentsPremium: 0,
            adminFee: 0,
            distribution: {
                URBAN: { freight: 0, volume: 0 },
                POSTIES: { freight: 0, volume: 0 },
                RURAL: { freight: 0, volume: 0 },
                POBOXES: { freight: 0, volume: 0 },
                COUNTERS: { freight: 0, volume: 0 },
            },
            subtotalMatrix: {URBAN: 0, POSTIES: 0, RURAL:0, POBOXES: 0, COUNTERS: 0}
        },
        quoteMeta: {
            id: "",
            name: "",
            details: "",
            validUntil: null,
            createdOn: null,
            updatedOn: null,
            createdBy: null,
            updatedBy: null,
            tags: [],
            crm: {},
            contact: {}
        },
        activeTerritory: "rm_d33ace88-d97a-2892-4b03-0c2331176a13",
        territories: [
            {
                id:"rm_d33ace88-d97a-2892-4b03-0c2331176a13",
                colour:"#110109",
                location: {
                    center: null,
                    address: null,
                    formatted: null
                },
                volumeSelections: {
                    URBAN: ["EXCLESIVE"],
                    POSTIES: ["EXCLUSIVE"],
                    RURAL: [],
                    POBOXES: [],
                    COUNTERS: []
                },
                volumesMatrix: {
                    URBAN: {INCLUSIVE:0, EXCLUSIVE:0},
                    POSTIES: {INCLUSIVE:0, EXCLUSIVE:0, BUSINESS: 0},
                    RURAL: {RESIDENTIAL:0, FARMER:0, DAIRY:0},
                    POBOXES: {RESIDENTIAL:0, BUSINESS:0, FARMER:0},
                    COUNTERS: {RESIDENTIAL:0, FARMER:0}
                },
                networkSelections: ["URBAN"],
                rounds: {}
            }
        ]
    }
 */

export const buildQuoteMeta = () => {
    const contact = contactStore.getState();

    const quoteMeta = {
        id: null,
        name: `Quote enquiry from ${contact.firstName} ${contact.lastName} at ${contact.companyName}`,
        details: "",
        validUntil: null,
        createdOn: null,
        updatedOn: null,
        createdBy: contact.email || null,
        updatedBy: null,
        tags: [],
        crm: {},
        contact,
    };

    if (contact.email) {
        quoteMeta.tags.push(contact.email);
    }
    return quoteMeta;
};

/**
 * This method combines stores to match required quote JSON structure
 */
export const buildQuoteStore = () => {
    const territory = territoryStore.getState();

    // network visibility has different structure - reshape it
    const { ...layers } = GMAP_ROUNDS_LAYERS;
    const networksSelections = territoryNetworksStore.getState();
    const networkVisibility = {};
    Object.keys(layers).forEach(
        (layer) =>
            (networkVisibility[layer] = networksSelections.includes(layer))
    );

    return combine({
        origin: "client",
        activeTerritory: territory.id,
        territories: [territory],
        networkVisibility,
        quoteDetails: quoteDetailsStore,
        quoteMeta: buildQuoteMeta(),
        printingSpecs: printDetailsStore,
    });
};

export const saveQuote = async () => {
    // Indicate the progress
    onSetApiInFlight(true);

    let quoteCollRef;
    try {
        quoteCollRef = collection(db, "quotes");
    } catch (error) {
        console.error("Could not initiate storage access to save quote!");
        console.error(error);
        onUserMessage({
            level: "warning",
            title: "Uh-uh",
            message:
                "It appears that you are offline, please try again when your connection is back!",
        });

        // No progress inflight
        onSetApiInFlight(false);

        return;
    }

    let token;
    if ((token = getFirebaseToken())) {
        const doc = buildQuoteStore().getState();

        // create doc
        const quoteDocRef = await addDoc(quoteCollRef, doc);
        doc.quoteMeta.id = quoteDocRef.id;

        // update tags with quote ID
        doc.quoteMeta.tags.push(doc.quoteMeta.id.toLowerCase());

        // async update meta, don't wait here
        updateDoc(quoteDocRef, {
            "quoteMeta.id": doc.quoteMeta.id,
            "quoteMeta.tags": doc.quoteMeta.tags,
        });

        try {
            // create meta
            const res = await fetch(process.env.URL_QUOTE_API, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Firebase-Token": token,
                },
                body: JSON.stringify(doc.quoteMeta),
            });

            const response = await res.json();

            // update quote meta agian - timestamps are getting updated
            updateDoc(quoteDocRef, {
                "quoteMeta.createdOn": response.result.createdon,
                "quoteMeta.updatedOn": response.result.updatedon,
            });

            // TODO: notify user the quote is received and reset everything
            onUserMessage({
                title: "Thank you!",
                message:
                    "Your quote has been successfully saved and our team will contact you as soon as possible. You can close the window or create another quote.",
            });
            startOver();

            // GA
            window.dataLayer.push({
                pagePath: "/quote-thank-you",
                pageTitle: "step 3 - quote submitted",
                event: "quotepageview",
            });
        } catch (error) {
            console.error("Could not save quote!");
            console.error(error);
            onUserMessage({
                level: "warning",
                title: "Uh-uh",
                message:
                    "Apologies, we could not save your quote details, please try again!",
            });
        }
    } else {
        console.error("Could not validate access to save quote!");
        onUserMessage({
            level: "warning",
            title: "Uh-uh",
            message:
                "Apologies, something went wrong, please refresh the page and try again!",
        });
    }

    // No progress inflight
    onSetApiInFlight(false);
};
